img.nc-client{
  width:3rem;height:3rem;
}
a:link {
  color: teal;
  text-decoration:none;
}
a:visited {
  color: #004c4c;
  text-decoration: none;
}
img.nc-client{
  width:3rem;height:3rem;
}
