/* span.nc-logo moved to App.css */
html,
body,
header.nc-hdr-lnd,
#intro {
  height: 100% !important
}

body.nc-dark {
  background-color: black;
}

nav.navbar-dark {
  background:rgba(62,69,81,.7)
}

/* scrolling-navbar */
.scr-navbar {
  padding: 0;
  background: linear-gradient(rgba(0, 0, 0, .6), rgba(0, 0, 0, .8));
}

.nav-menu {
  background: linear-gradient(rgba(0, 0, 0, .3), rgba(0, 0, 0, .5));
  transition: all .7s;
  position: fixed;
  top: 0;
  width: 100%;
}

.menu-item {
  font-size: 13px;
  letter-spacing: 1px;
  color: #eee;
  transition: color .5s;
}

.menu-item:hover {
  color: #70aed2;
}

.top-nav-collapse {
  background-color: #24355c
}

@keyframes anim {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

/* End of Scrolling-navbar */


nav.nc-navbar-dark,
footer div.nc-footer-dark,
a.nc-list-group-dark:hover {
  background-color: #49515A;
}

div.nc-list-group-dark,
a.nc-list-group-dark,
input[type=text].nc-search-dark {
  background-color: #1B2631
}

/* header.nc-hdr-doc{
  height:2rem;
} */

h1.nc-ttl-dark-theme,
h2.nc-ttl-dark-theme,
h3.nc-ttl-dark-theme,
h4.nc-ttl-dark-theme,
h5.nc-ttl-dark-theme,
h6.nc-ttl-dark-theme {
  color: white
}

p.nc-txt-dark-theme,
div.nc-txt-dark-theme,
a.nc-pg-item,
a.nc-pg-item page-item,
a.nc-pg-item page-link,
input[type=text].nc-search-dark {
  color: #BDC3C7 !important
}

a.nc-pg-item:hover,
a.nc-pg-item page-item:hover,
a.nc-pg-item page-link:hover {
  background-color: #49515A;
}

#intro.view {
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-attachment: fixed !important
}

.nc-fixed-img {
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  height: 100%;
  width: 100%
}

.nc-container-intro {
  height: 100vh
}

h4.min-width-22rem {
  min-width: 22rem
}

a.nc-lp-btn {
  min-width: 10rem;
  margin-left: auto;
  margin-right: auto
}

div.nc-lp-min-width-28rem {
  min-width: 28rem
}

.nc-container-intro {
  height: 100vh
}

div.nc-cls {
  clear: both !important;
  text-align: center !important
}

div.nc-dblk {
  display: block !important
}

div.nc-dnn {
  display: none !important
}

hr.nc-60 {
  width: 60px !important
}

.nc-grad {
  background: linear-gradient(to right, #bf953f, #fcf6ba, #b38728, #fbf5b7, #aa771c);
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important
}

@media screen and (-ms-high-contrast:active),
screen and (-ms-high-contrast:none) {
  .nc-grad {
    background: transparent;
    color: #fcf6ba
  }

  .nc-grad:hover {
    background: transparent;
    color: gold
  }
}

span.clearfix.d-none.d-sm-inline-block {
  margin-left: 0.3rem;
}

.nc-grad:hover {
  background: -webkit-gradient(linear, 100% 0, 0 0, from(#f00), to(#ff0));
  background: -webkit-linear-gradient(right, #f00, #ff0);
  background: -moz-linear-gradient(right, #f00, #ff0);
  background: -o-linear-gradient(right, #f00, #ff0);
  background: linear-gradient(to right, #f00, #ff0)
}

.top-nav-collapse {
  background-color: #24355c
}

svg.nc-logo {
  transform: rotate(30deg);
  margin-top: .275rem;
  width: 1.375rem;
  height: 1.375rem;
  stroke: none;
  fill-rule: nonzero;
  fill: #c00;
  fill-opacity: 1
}

svg.nc-logo:hover {
  fill: #bf953f
}

.nc-dropdown {
  padding-top: 0 !important;
  padding-bottom: 0 !important
}

img.irpt,
img.irptl,
img.irptr {
  width: 31.875rem;
  height: 17.9296875rem;
  margin-bottom: .325rem;
  padding: 0
}

img.irptl {
  float: left;
  margin-right: 1rem
}

img.irptr {
  float: right;
  margin-left: 1rem
}

div.irpt,
div.irptl,
div.irptr {
  margin-bottom: .375rem;
  padding: 0 !important
}

div.irptl {
  float: left !important;
  margin-right: 1rem !important
}

div.irptr {
  float: right !important;
  margin-left: 1rem !important
}

.xx {
  list-style-image: url("data:image/svg+xml,%3Csvg xmlns=http://www.w3.org/2000/svg viewBox=0 0 10 10 width=10 height=10%3E%3Ccircle fill=#ee2e24 cx=5 cy=5 r=3/%3E%3C/svg%3E")
}

div.nc-form-group-img {
  position: relative
}

a.nc-srch-show-hide-zone {
  position: absolute;
  top: .25rem;
  left: 1rem;
  height: 1.5rem;
  width: 1.5rem;
  background-color: transparent;
  border: solid .0625rem transparent;
  background: url('data:image/svg+xml,%3Csvg width="1.5rem" height="1.5rem" viewBox="0 0 24 24"%3E%3Cg fill="rgb(69,90,100)"%3E%3Cpath class="nc-svg-blue-grey" d="M19.4271164,21.4271164 C18.0372495,22.4174803 16.3366522,23 14.5,23 C9.80557939,23 6,19.1944206 6,14.5 C6,9.80557939 9.80557939,6 14.5,6 C19.1944206,6 23,9.80557939 23,14.5 C23,16.3366522 22.4174803,18.0372495 21.4271164,19.4271164 L27.0119176,25.0119176 C27.5621186,25.5621186 27.5575313,26.4424687 27.0117185,26.9882815 L26.9882815,27.0117185 C26.4438648,27.5561352 25.5576204,27.5576204 25.0119176,27.0119176 L19.4271164,21.4271164 L19.4271164,21.4271164 Z M14.5,21 C18.0898511,21 21,18.0898511 21,14.5 C21,10.9101489 18.0898511,8 14.5,8 C10.9101489,8 8,10.9101489 8,14.5 C8,18.0898511 10.9101489,21 14.5,21 L14.5,21 Z" /%3E%3C/g%3E%3C/svg%3E') scroll no-repeat;
  background-position: center
}

a.nc-srch-show-hide-zone:hover {
  position: absolute;
  top: .25rem;
  left: 1rem;
  height: 1.5rem;
  width: 1.5rem;
  background: url('data:image/svg+xml,%3Csvg width="1.5rem" height="1.5rem" viewBox="0 0 24 24"%3E%3Cg fill="rgb(23,162,184)"%3E%3Cpath class="nc-svg-blue-grey-hover" d="M19.4271164,21.4271164 C18.0372495,22.4174803 16.3366522,23 14.5,23 C9.80557939,23 6,19.1944206 6,14.5 C6,9.80557939 9.80557939,6 14.5,6 C19.1944206,6 23,9.80557939 23,14.5 C23,16.3366522 22.4174803,18.0372495 21.4271164,19.4271164 L27.0119176,25.0119176 C27.5621186,25.5621186 27.5575313,26.4424687 27.0117185,26.9882815 L26.9882815,27.0117185 C26.4438648,27.5561352 25.5576204,27.5576204 25.0119176,27.0119176 L19.4271164,21.4271164 L19.4271164,21.4271164 Z M14.5,21 C18.0898511,21 21,18.0898511 21,14.5 C21,10.9101489 18.0898511,8 14.5,8 C10.9101489,8 8,10.9101489 8,14.5 C8,18.0898511 10.9101489,21 14.5,21 L14.5,21 Z " /%3E%3C/g%3E%3C/svg%3E') scroll no-repeat;
  background-position: center;
  cursor: pointer
}

a.nc-srch-show-hide-zone-alt {
  position: absolute;
  top: .5rem;
  right: 1.1rem;
  height: 1.5rem;
  width: 2rem;
  background-color: transparent;
  border: solid .0625rem transparent;
  background: url('data:image/svg+xml,%3Csvg width="1.5rem" height="1.5rem" viewBox="0 0 24 24" version="1.1"%3E%3Cg fill="rgb(69,90,100)"%3E%3Cpath class="nc-svg-blue-grey" d="M 15.28125 12.390625 L 20.207031 7.464844 C 20.929688 6.742188 20.929688 5.558594 20.207031 4.839844 L 19.550781 4.183594 C 18.828125 3.460938 17.648438 3.460938 16.925781 4.183594 L 12 9.105469 L 7.074219 4.179688 C 6.351562 3.460938 5.171875 3.460938 4.449219 4.179688 L 3.792969 4.835938 C 3.070312 5.558594 3.070312 6.742188 3.792969 7.464844 L 8.714844 12.386719 L 3.792969 17.3125 C 3.070312 18.035156 3.070312 19.21875 3.792969 19.941406 L 4.449219 20.597656 C 5.171875 21.320312 6.351562 21.320312 7.074219 20.597656 L 12 15.671875 L 16.925781 20.597656 C 17.648438 21.320312 18.828125 21.320312 19.550781 20.597656 L 20.207031 19.941406 C 20.929688 19.21875 20.929688 18.035156 20.207031 17.3125 Z M 15.28125 12.390625" /%3E%3C/g%3E%3C/svg%3E') scroll no-repeat;
  background-position: center
}

a.nc-srch-show-hide-zone-alt:hover {
  background: url('data:image/svg+xml,%3Csvg width="1.5rem" height="1.5rem" viewBox="0 0 24 24" version="1.1"%3E%3Cg fill="rgb(23,162,184)"%3E%3Cpath class="nc-svg-blue-grey-hover" d="M 15.28125 12.390625 L 20.207031 7.464844 C 20.929688 6.742188 20.929688 5.558594 20.207031 4.839844 L 19.550781 4.183594 C 18.828125 3.460938 17.648438 3.460938 16.925781 4.183594 L 12 9.105469 L 7.074219 4.179688 C 6.351562 3.460938 5.171875 3.460938 4.449219 4.179688 L 3.792969 4.835938 C 3.070312 5.558594 3.070312 6.742188 3.792969 7.464844 L 8.714844 12.386719 L 3.792969 17.3125 C 3.070312 18.035156 3.070312 19.21875 3.792969 19.941406 L 4.449219 20.597656 C 5.171875 21.320312 6.351562 21.320312 7.074219 20.597656 L 12 15.671875 L 16.925781 20.597656 C 17.648438 21.320312 18.828125 21.320312 19.550781 20.597656 L 20.207031 19.941406 C 20.929688 19.21875 20.929688 18.035156 20.207031 17.3125 Z M 15.28125 12.390625" /%3E%3C/g%3E%3C/svg%3E') scroll no-repeat;
  background-position: center;
  cursor: pointer
}

.nc-srch-img {
  padding-left: 3rem;
  padding-right: 3rem
}

.nc-btn-submit-hdn,
.nc-txt-pgnum-hdn {
  padding: 0;
  border: 0;
  background-color: transparent;
  color: transparent;
  width: 0;
  height: 0
}

div.nc-media-grd {
  padding: .25rem !important
}

div.nc-media-rpt-rht {
  padding-right: .5rem !important
}

div.nc-media-rpt-lft {
  padding-left: .5rem !important
}

a.nc-lnk {
  color: #24355c
}

a.nc-list-group-item {
  padding-top: .25rem;
  padding-bottom: .25rem;
  border: 0
}

div.nc-mp0 {
  margin: 0 !important;
  padding: 0 !important
}

div.nc-map-container {
  height: 400px
}

div.card-deck.nc-plr-075 {
  padding-left:.75rem;
  padding-right:.75rem
}

div.card.nc-mlr-075 {
  margin-left:.75rem;
  margin-right:.75rem
}

a.nc-home,
a.nc-fullscreen,
a.nc-arr.carousel-control-next,
a.nc-arr.carousel-control-prev {
  filter: none;
  opacity: .1
}

a.nc-home:hover,
a.nc-home:focus,
a.nc-fullscreen:hover,
a.nc-fullscreen:focus,
a.nc-arr.carousel-control-next:hover,
a.nc-arr.carousel-control-next:focus,
a.nc-arr.carousel-control-prev:hover,
a.nc-arr.carousel-control-prev:focus {
  filter: none;
  opacity: 1
}

a.nc-fullscreen,
a.nc-home {
  position: absolute;
  top: 0;
  bottom: 85%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center
}

a.nc-fullscreen {
  right: 0
}

a.nc-home {
  left: 0
}

.nc-btn-v-grp.btn {
  margin: .5rem 0 .5rem 0
}

div.nc-grd {
  padding: 0 .3125rem 0 .3125rem !important
}

.list-group-item.list-group-item-action.nc-list-group-item {
  border: solid transparent .0625rem
}

.list-group-item.list-group-item-action.nc-list-group-item:hover {
  border: solid silver .0625rem
}

.btn-link.nc-btn-img,
.btn-link.nc-btn-img-sm {
  padding: 1px 12px 1px 12px !important
}

.btn-link.nc-btn-img-lg {
  padding: 1px 12px 12px 12px !important
}

.btn-link.nc-btn-img-lg-lft {
  padding: 1px 20px 1px 1px !important;
  line-height: 2.5rem
}

.btn-link.nc-btn-img-lft {
  padding: .5rem .5rem .05rem .05rem !important
}

.btn-link.nc-btn-img-sm {
  width: 25px !important
}

.nc-btn-img,
.nc-btn-img-lft,
.nc-btn-img-lg,
.nc-btn-img-lg-lft,
.nc-btn-img-sm,
.nc-ctrl-lbl {
  color: #455a64 !important
}

.nc-btn-img:hover,
.nc-btn-img-lft:hover,
.nc-btn-img-lg:hover,
.nc-btn-img-lg-lft:hover,
.nc-btn-img-sm:hover,
.nc-ctrl-lbl:hover {
  color: #17a2b8 !important
}

.popover {
  background-color: white;
  color: dimgray;
  border-color: silver;
  box-shadow: 0 0 0 .05rem rgba(220, 53, 69, 0.25)
}

.nc-pop-ttl {
  font-weight: bold;
  font-size: 1rem
}

.nc-pop-cnt {
  font-size: .8rem
}

@media(max-width:768px) {
  .navbar:not(.top-nav-collapse) {
    background: #24355c
  }
}

@media(min-width:800px) and (max-width:850px) {
  .navbar:not(.top-nav-collapse) {
    background: #24355c
  }
}

html,
body,
header.nc-hdr-lnd,
#intro {
  height: 100% !important
}

header.nc-hdr-doc {
  height: 3.133rem
}

#intro.view {
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-attachment: fixed !important
}

.nc-fixed-img {
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  height: 100%;
  width: 100%
}

.nc-container-intro {
  height: 100vh
}

h4.min-width-22rem {
  min-width: 22rem
}

a.nc-lp-btn {
  min-width: 10rem;
  margin-left: auto;
  margin-right: auto
}

div.nc-lp-min-width-28rem {
  min-width: 28rem
}

.nc-container-intro {
  height: 100vh
}

div.nc-cls {
  clear: both !important;
  text-align: center !important
}

div.nc-dblk {
  display: block !important
}

div.nc-dnn {
  display: none !important
}

hr.nc-60 {
  width: 60px !important
}

.nc-grad {
  background: linear-gradient(to right, #bf953f, #fcf6ba, #b38728, #fbf5b7, #aa771c);
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important
}

@media screen and (-ms-high-contrast:active),
screen and (-ms-high-contrast:none) {
  .nc-grad {
    background: transparent;
    color: #fcf6ba
  }

  .nc-grad:hover {
    background: transparent;
    color: gold
  }
}

.nc-grad:hover {
  background: -webkit-gradient(linear, 100% 0, 0 0, from(#f00), to(#ff0));
  background: -webkit-linear-gradient(right, #f00, #ff0);
  background: -moz-linear-gradient(right, #f00, #ff0);
  background: -o-linear-gradient(right, #f00, #ff0);
  background: linear-gradient(to right, #f00, #ff0)
}

svg.nc-logo {
  transform: rotate(30deg);
  margin-top: .275rem;
  width: 1.375rem;
  height: 1.375rem;
  stroke: none;
  fill-rule: nonzero;
  fill: #c00;
  fill-opacity: 1
}

svg.nc-logo:hover {
  fill: #bf953f
}

.nc-dropdown {
  padding-top: 0 !important;
  padding-bottom: 0 !important
}

img.irpt,
img.irptl,
img.irptr {
  width: 31.875rem;
  height: 17.9296875rem;
  margin-bottom: .325rem;
  padding: 0
}

img.irptl {
  float: left;
  margin-right: 1rem
}

img.irptr {
  float: right;
  margin-left: 1rem
}

div.irpt,
div.irptl,
div.irptr {
  margin-bottom: .375rem;
  padding: 0 !important
}

div.irptl {
  float: left !important;
  margin-right: 1rem !important
}

div.irptr {
  float: right !important;
  margin-left: 1rem !important
}

.xx {
  list-style-image: url("data:image/svg+xml,%3Csvg viewBox=0 0 10 10 width=10 height=10%3E%3Ccircle fill=#ee2e24 cx=5 cy=5 r=3/%3E%3C/svg%3E")
}

div.nc-form-group-img {
  position: relative
}

a.nc-srch-show-hide-zone {
  position: absolute;
  top: .25rem;
  left: 1rem;
  height: 1.5rem;
  width: 1.5rem;
  background-color: transparent;
  border: solid .0625rem transparent;
  background: url('data:image/svg+xml,%3Csvg width="1.5rem" height="1.5rem" viewBox="0 0 24 24"%3E%3Cg fill="rgb(69,90,100)"%3E%3Cpath class="nc-svg-blue-grey" d="M19.4271164,21.4271164 C18.0372495,22.4174803 16.3366522,23 14.5,23 C9.80557939,23 6,19.1944206 6,14.5 C6,9.80557939 9.80557939,6 14.5,6 C19.1944206,6 23,9.80557939 23,14.5 C23,16.3366522 22.4174803,18.0372495 21.4271164,19.4271164 L27.0119176,25.0119176 C27.5621186,25.5621186 27.5575313,26.4424687 27.0117185,26.9882815 L26.9882815,27.0117185 C26.4438648,27.5561352 25.5576204,27.5576204 25.0119176,27.0119176 L19.4271164,21.4271164 L19.4271164,21.4271164 Z M14.5,21 C18.0898511,21 21,18.0898511 21,14.5 C21,10.9101489 18.0898511,8 14.5,8 C10.9101489,8 8,10.9101489 8,14.5 C8,18.0898511 10.9101489,21 14.5,21 L14.5,21 Z" /%3E%3C/g%3E%3C/svg%3E') scroll no-repeat;
  background-position: center
}

a.nc-srch-show-hide-zone:hover {
  position: absolute;
  top: .25rem;
  left: 1rem;
  height: 1.5rem;
  width: 1.5rem;
  background: url('data:image/svg+xml,%3Csvg width="1.5rem" height="1.5rem" viewBox="0 0 24 24"%3E%3Cg fill="rgb(23,162,184)"%3E%3Cpath class="nc-svg-blue-grey-hover" d="M19.4271164,21.4271164 C18.0372495,22.4174803 16.3366522,23 14.5,23 C9.80557939,23 6,19.1944206 6,14.5 C6,9.80557939 9.80557939,6 14.5,6 C19.1944206,6 23,9.80557939 23,14.5 C23,16.3366522 22.4174803,18.0372495 21.4271164,19.4271164 L27.0119176,25.0119176 C27.5621186,25.5621186 27.5575313,26.4424687 27.0117185,26.9882815 L26.9882815,27.0117185 C26.4438648,27.5561352 25.5576204,27.5576204 25.0119176,27.0119176 L19.4271164,21.4271164 L19.4271164,21.4271164 Z M14.5,21 C18.0898511,21 21,18.0898511 21,14.5 C21,10.9101489 18.0898511,8 14.5,8 C10.9101489,8 8,10.9101489 8,14.5 C8,18.0898511 10.9101489,21 14.5,21 L14.5,21 Z " /%3E%3C/g%3E%3C/svg%3E') scroll no-repeat;
  background-position: center;
  cursor: pointer
}

a.nc-srch-show-hide-zone-alt {
  position: absolute;
  top: .5rem;
  right: 1.1rem;
  height: 1.5rem;
  width: 2rem;
  background-color: transparent;
  border: solid .0625rem transparent;
  background: url('data:image/svg+xml,%3Csvg width="1.5rem" height="1.5rem" viewBox="0 0 24 24" version="1.1"%3E%3Cg fill="rgb(69,90,100)"%3E%3Cpath class="nc-svg-blue-grey" d="M 15.28125 12.390625 L 20.207031 7.464844 C 20.929688 6.742188 20.929688 5.558594 20.207031 4.839844 L 19.550781 4.183594 C 18.828125 3.460938 17.648438 3.460938 16.925781 4.183594 L 12 9.105469 L 7.074219 4.179688 C 6.351562 3.460938 5.171875 3.460938 4.449219 4.179688 L 3.792969 4.835938 C 3.070312 5.558594 3.070312 6.742188 3.792969 7.464844 L 8.714844 12.386719 L 3.792969 17.3125 C 3.070312 18.035156 3.070312 19.21875 3.792969 19.941406 L 4.449219 20.597656 C 5.171875 21.320312 6.351562 21.320312 7.074219 20.597656 L 12 15.671875 L 16.925781 20.597656 C 17.648438 21.320312 18.828125 21.320312 19.550781 20.597656 L 20.207031 19.941406 C 20.929688 19.21875 20.929688 18.035156 20.207031 17.3125 Z M 15.28125 12.390625" /%3E%3C/g%3E%3C/svg%3E') scroll no-repeat;
  background-position: center
}

a.nc-srch-show-hide-zone-alt:hover {
  background: url('data:image/svg+xml,%3Csvg width="1.5rem" height="1.5rem" viewBox="0 0 24 24" version="1.1"%3E%3Cg fill="rgb(23,162,184)"%3E%3Cpath class="nc-svg-blue-grey-hover" d="M 15.28125 12.390625 L 20.207031 7.464844 C 20.929688 6.742188 20.929688 5.558594 20.207031 4.839844 L 19.550781 4.183594 C 18.828125 3.460938 17.648438 3.460938 16.925781 4.183594 L 12 9.105469 L 7.074219 4.179688 C 6.351562 3.460938 5.171875 3.460938 4.449219 4.179688 L 3.792969 4.835938 C 3.070312 5.558594 3.070312 6.742188 3.792969 7.464844 L 8.714844 12.386719 L 3.792969 17.3125 C 3.070312 18.035156 3.070312 19.21875 3.792969 19.941406 L 4.449219 20.597656 C 5.171875 21.320312 6.351562 21.320312 7.074219 20.597656 L 12 15.671875 L 16.925781 20.597656 C 17.648438 21.320312 18.828125 21.320312 19.550781 20.597656 L 20.207031 19.941406 C 20.929688 19.21875 20.929688 18.035156 20.207031 17.3125 Z M 15.28125 12.390625" /%3E%3C/g%3E%3C/svg%3E') scroll no-repeat;
  background-position: center;
  cursor: pointer
}

.nc-srch-img {
  padding-left: 3rem;
  padding-right: 3rem
}

.nc-btn-submit-hdn,
.nc-txt-pgnum-hdn {
  padding: 0;
  border: 0;
  background-color: transparent;
  color: transparent;
  width: 0;
  height: 0
}

div.nc-media-grd {
  padding: .25rem !important
}

div.nc-media-rpt-rht {
  padding-right: .5rem !important
}

div.nc-media-rpt-lft {
  padding-left: .5rem !important
}

a.nc-lnk {
  color: #24355c
}

a.nc-list-group-item {
  padding-top: .25rem;
  padding-bottom: .25rem;
  border: 0
}

div.nc-mp0 {
  margin: 0 !important;
  padding: 0 !important
}

div.nc-map-container {
  height: 400px
}

div.card-deck.nc-plr-075 {
  padding-left: .75rem;
  padding-right: .75rem
}

div.card.nc-mlr-075 {
  margin-left: .75rem;
  margin-right: .75rem
}

a.nc-home,
a.nc-fullscreen,
a.nc-arr.carousel-control-next,
a.nc-arr.carousel-control-prev {
  filter: none;
  opacity: .1
}

a.nc-home:hover,
a.nc-home:focus,
a.nc-fullscreen:hover,
a.nc-fullscreen:focus,
a.nc-arr.carousel-control-next:hover,
a.nc-arr.carousel-control-next:focus,
a.nc-arr.carousel-control-prev:hover,
a.nc-arr.carousel-control-prev:focus {
  filter: none;
  opacity: 1
}

a.nc-fullscreen,
a.nc-home {
  position: absolute;
  top: 0;
  bottom: 85%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center
}

a.nc-fullscreen {
  right: 0
}

a.nc-home {
  left: 0
}

.nc-btn-v-grp.btn {
  margin: .5rem 0 .5rem 0
}

div.nc-grd {
  padding: 0 .3125rem 0 .3125rem !important
}

.list-group-item.list-group-item-action.nc-list-group-item {
  border: solid transparent .0625rem
}

.list-group-item.list-group-item-action.nc-list-group-item:hover {
  border: solid silver .0625rem
}

.btn-link.nc-btn-img,
.btn-link.nc-btn-img-sm {
  padding: 1px 12px 1px 12px !important
}

.btn-link.nc-btn-img-lg {
  padding: 1px 12px 12px 12px !important
}

.btn-link.nc-btn-img-lg-lft {
  padding: 1px 20px 1px 1px !important;
  line-height: 2.5rem
}

.btn-link.nc-btn-img-lft {
  padding: .5rem .5rem .05rem .05rem !important
}

.btn-link.nc-btn-img-sm {
  width: 25px !important
}

.nc-btn-img,
.nc-btn-img-lft,
.nc-btn-img-lg,
.nc-btn-img-lg-lft,
.nc-btn-img-sm,
.nc-ctrl-lbl {
  color: #455a64 !important
}

.nc-btn-img:hover,
.nc-btn-img-lft:hover,
.nc-btn-img-lg:hover,
.nc-btn-img-lg-lft:hover,
.nc-btn-img-sm:hover,
.nc-ctrl-lbl:hover {
  color: #17a2b8 !important
}

.popover {
  background-color: white;
  color: dimgray;
  border-color: silver;
  box-shadow: 0 0 0 .05rem rgba(220, 53, 69, 0.25)
}

.nc-pop-ttl {
  font-weight: bold;
  font-size: 1rem
}

.nc-pop-cnt {
  font-size: .8rem
}

div.nc-same-h {
  display: flex;
  flex-wrap: wrap
}

li.nc-dropdown-sm {
  height: 1.75rem
}

li.nc-nav-item-soc,
span.nc-nav-item-soc {
  width: 1.75rem
}

.h1,
h1,
h1.nc-ttl {
  font-size: 2rem !important
}

.nc-body-txt,
.card-title h1,
.card-title h2,
.card-title h3,
.card-title h4,
.card-title h5,
.card-title h6 {
  font-weight: 400 !important
}

@media(max-width:768px) {
  .navbar:not(.top-nav-collapse) {
    background: #24355c
  }
}

@media(min-width:800px) and (max-width:850px) {
  .navbar:not(.top-nav-collapse) {
    background: #24355c
  }
}

@media(min-width:1210px) {
  .container {
    max-width: 1200px
  }

  div .nc-pin-doc {
    max-width: 800px
  }
}

@media(min-width:1310px) {
  .container {
    max-width: 1300px
  }
}

@media(min-width:1410px) {
  .container {
    max-width: 1400px
  }
}

@media(min-width:1510px) {
  .container {
    max-width: 1500px
  }
}

@media(min-width:1610px) {
  .container {
    max-width: 1600px
  }
}
