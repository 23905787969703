@import "clients.scss";
@import "client-list.scss";
@import "app-footer.scss";
@import "app-header.scss";
@import "social-bar-btm.scss";
@import "social-bar-top.scss";


$body-bg: #fff;

@font-face {
  font-family: "Cambria Math";
  panose-1: 2 4 5 3 5 4 6 3 2 4;
}

@font-face {
  font-family: Calibri;
  panose-1: 2 15 5 2 2 2 4 3 2 4;
}

p.csn-nrm, li.csn-nrm, div.csn-nrm, blockquote.csn-nrm {
  font-family: "Calibri","sans-serif";
  font-size:1.1rem;
}

blockquote.csn-nrm {
  padding-left: 1rem
}

a:link {
  color: teal;
  text-decoration: none;
}

a:visited {
  color: #004c4c;
  text-decoration: none;
}

div.csn-client {
  margin: 3rem 3rem 3rem 3rem;
  font-weight: 400 !important;
}

svg#clouds {
  position: fixed;
  bottom: -160px;
  left: -230px;
  z-index: -10;
  width: 1920px;
}
